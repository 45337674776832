import html2canvas from "html2canvas";
import { uploadScreenshotToStorage } from "../db/examMethods";

export const captureScreenshot = async (element, examId, userId) => {
  // try {
  //   const canvas = await html2canvas(element);
  //   const screenshotUrl = canvas.toDataURL(); // The screenshot in base64 format
  //   console.log(screenshotUrl);
  //   await uploadScreenshotToStorage(screenshotUrl, examId, userId).catch(
  //     (error) => {
  //       console.error("Error uploading screenshot:", error);
  //     }
  //   );
  //   // You can save the image or display it as needed
  //   // For example, create a link to download the image
  //   // const link = document.createElement("a");
  //   // link.href = screenshotUrl;
  //   // link.download = "screenshot.png";
  //   // link.click();
  // } catch (error) {
  //   console.log("error", error);
  // }
};
